<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <div v-if="todayGraphs.length > 0 || pastGraphs.length > 0">
                <div v-if="todayGraphs.length > 0" class="row">
                    <div v-for="(graph, index) in todayGraphs" :key="index" class="col-md-6 col-12">
                        <b-card class="h-550">
                            <b-card-title>{{$t(`graphs.${graph.enum_name}`)}}</b-card-title>
                            <VueApexCharts class="align-vertical-middle" :class="isDark ? 'white-text' : 'black-text'" v-if="dataLoaded" :ref="graph.enum_name" :options="chartConfigs.getConfig(graph.enum_name, graph.config)" :series="getData(graph.data, graph.series)" height='450px' />
                        </b-card>
                    </div>
                </div>
                <div  v-if="pastGraphs.length > 0">
                    <b-card class="col-12">
                        <div>
                            <b-card-title ref="pastChartTitle">{{$t('graphs.past_data')}}</b-card-title>
                        </div>
                        <slot></slot>
                    </b-card>
                </div>
                <div v-if="pastGraphs.length > 0" class="row">
                    <div v-for="(graph, index) in pastGraphs" :key="index" class="col-md-6 col-12">
                        <b-card class="h-550">
                            <b-card-title>{{$t(`graphs.${graph.enum_name}`)}}</b-card-title>
                            <VueApexCharts :class="isDark ? 'white-text' : 'black-text'" :ref="graph.enum_name" v-if="dataLoaded" :options="chartConfigs.getConfig(graph.enum_name, graph.config)" :series="getData(graph.data, graph.series)" height='450px' />
                            <span v-else>{{$t('graphs.no_data')}}</span>
                        </b-card>
                    </div>
                </div>
            </div>
            <div v-else-if="dataLoaded">
                {{$t('graphs.no_graphs')}}.
            </div>
        </b-overlay>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import chartConfigs from '@/libs/charts/chartsConfigs/main'

    import {
        BCard,
        BCardTitle,
        BOverlay
    } from 'bootstrap-vue'
    import appConfig from '../../../store/app-config'
    import * as Sentry from '@sentry/vue'
    

    export default {
        computed: {
            appConfig() {
                return appConfig
            },
            isDark() {
                return this.$store.state.appConfig.layout.skin === 'dark'
            }
        },
        components: {
            VueApexCharts,
            BCard,
            BCardTitle,
            BOverlay
        },

        data() {
            return {
                chartConfigs,
                graphs: [],
                dataLoaded: false,
                todayGraphs: [],
                pastGraphs: []
            }
        },

        watch: {
            async startDate() {
                await this.$nextTick()
                await this.loadData()
            },
            async endDate() {
                await this.$nextTick()
                await this.loadData()
            },
            isDark() {
                this.checkColor()
            }
        },

        methods: {
            async loadData(initialLoad = false) {
                this.dataLoaded = false
                try {
                    const data = {
                        'start_date': this.startDate.getTime(),
                        'end_date': this.endDate.getTime()
                    }
                    const response =  await this.$http.post('/api/dashboard/v1/dashboard', data)
                    this.graphs = response.data.data
                    this.todayGraphs = this.graphs.filter((item) => item.type === 'today')
                    this.pastGraphs = this.graphs.filter((item) => item.type === 'past')
                              
                    if (!initialLoad) {
                        //wait for the graphs to load
                        //await this.$nextTick() not working
                        //TODO: find a better way to do this
                        setTimeout(() => {
                            const rect = this.$refs.pastChartTitle.getBoundingClientRect()                     
                            window.scrollTo({
                                top: rect.top + 140,
                                behavior: 'smooth'
                            })
                        }, 500)
                    }
                    
                    this.checkColor()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true                    
                }

            },

            getData(data, series) {
                return data ?? series ?? []
            },

            checkColor() {
                const graph = this.pastGraphs.find(x => x.enum_name === 'SALES_BY_WEEK_IN_RANGE')
                if (graph) {
                    if (graph.config.plotOptions.heatmap.colorScale.ranges[0]) {
                        graph.config.plotOptions.heatmap.colorScale.ranges[0].color = this.$store.state.appConfig.layout.skin === 'dark' ? '#474747' : '#ffffff'
                    }
                }
            }
        },

        props: {
            startDate: {
                type: Date
            },
            endDate: {
                type: Date
            }
        },

        async mounted() {
            await this.loadData(true)            
        }
    }
</script>

<style scoped>
    .h-550 {
        height: 550px;
    }

    .align-vertical-middle {
        height: 450px;
        display: flex;
        align-items: center;
    }
</style>