import {ChartType} from '@/libs/enums/Charts'
import SALES_COMPARE_LAST_WEEK from '@/libs/charts/chartsConfigs/SALES_COMPARE_LAST_WEEK'
import SOLD_BY_PAYMENT_TYPE from '@/libs/charts/chartsConfigs/SOLD_BY_PAYMENT_TYPE'
import SOLD_BY_HOUR_IN_RANGE from '@/libs/charts/chartsConfigs/SOLD_BY_HOUR_IN_RANGE'
import SOLD_BY_PAYMENT_TYPE_IN_RANGE from '@/libs/charts/chartsConfigs/SOLD_BY_PAYMENT_TYPE_IN_RANGE'
import PRODUCT_SOLD_IN_RANGE from '@/libs/charts/chartsConfigs/PRODUCT_SOLD_IN_RANGE'
import SALES_BY_WEEK_IN_RANGE from '@/libs/charts/chartsConfigs/SALES_BY_WEEK_IN_RANGE'


const chartConfigsMap = {
    [ChartType.SalesCompareLastWeek]: SALES_COMPARE_LAST_WEEK.getConfig,
    [ChartType.SoldByPaymentType]: SOLD_BY_PAYMENT_TYPE.getConfig,
    [ChartType.SoldByHourInRange]: SOLD_BY_HOUR_IN_RANGE.getConfig,
    [ChartType.SoldByPaymentTypeInRange]: SOLD_BY_PAYMENT_TYPE_IN_RANGE.getConfig,
    [ChartType.ProductSoldInRange]: PRODUCT_SOLD_IN_RANGE.getConfig,
    [ChartType.SalesByWeekInRange]: SALES_BY_WEEK_IN_RANGE.getConfig
}

function getConfig(chart, param) {
    //const parsedData = param !== '' ? JSON.parse(param) : param
    return chartConfigsMap[chart](param)
}

export default {
    chartConfigsMap,
    getConfig
}