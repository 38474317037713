import {baseChartOption, mergeObjects} from '@/libs/charts/main'
import dayjs from 'dayjs'

const salesCompareLastWeek = () => {
    return {
        ...baseChartOption,
        chart: {
            type: 'radar'
        },
        stroke: {
            curve: 'smooth'
        },
        plotOptions: {
            radar: {
                polygons: {
                    strokeColors: '#b3b3b3',
                    strokeWidth: 1,
                    connectorColors: '#b3b3b3'
                }
            }
        },
        xaxis: {
            labels: {
                formatter: (value) => {
                    return dayjs().day(parseInt(value)).format('dddd')
                }
            }
        },
        yaxis: {
            labels: {
                show: true,
                formatter: (item) => {
                    return item / 1000000
                }
            }
        },
        tooltip: {
            enabled: true,
            x: {
                show: true,
                formatter: (item) => {
                    return dayjs().day(parseInt(item)).format('dddd')
                }
            },
            y: {
                title: {
                    formatter: () => {
                        return 'Prodano:'
                    }
                },
                show: true,
                formatter: (item) => {
                    return `${(item / 1000000).toFixed(2)} €`
                }
            }
        },
        legend: {
            position: 'top'
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(salesCompareLastWeek(), rawConfig)
    } else {
        return salesCompareLastWeek()
    }
}
export default {
    getConfig
}